@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Karla');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=DM+Sans');
@import url('https://fonts.cdnfonts.com/css/instagram-sans-2');
@import url('https://fonts.cdnfonts.com/css/gilroy-bold');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --ReactInputVerificationCode-itemWidth: 2.2rem;
  --ReactInputVerificationCode-itemHeight: 2.2rem;
  --ReactInputVerificationCode-itemSpacing: 1rem;

  /* Styles for larger screens (desktop) */
}

li {
  font-family: 'Karla';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.backgroundImage {
  background-image: url(./assets/img//loading-img.jpg);
  background-position: 0px 35vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fcfcfc;
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: #e4e3ff !important;
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  background-color: #e4e3ff !important;
  color: #000 !important;
  font-weight: bolder !important;
}

.pull-indicator {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
  transition: transform 0.2s ease-in-out;
  z-index: 100000;
}

.pull-indicator i {
  transition: transform 0.2s ease-in-out;
}

.pull-indicator.flip i {
  transform: rotate(180deg);
}

@font-face {
  font-family: 'Proxima';
  src:
    local('ProximaNovaFont'),
    url('./assets/fonts//ProximaNovaFont.otf') format('truetype');
}

@media (min-width: 768px) {
  body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }

  .layout-parent {
    min-width: 450px;
    max-width: 450px; /* Adjust this to your desired max-width for mobile-like appearance */
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  @keyframes blink {
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
  }
}
